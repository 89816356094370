import { FC, ReactNode } from 'react';

import { Box } from '@/common/components/Display/Box';
import { Group } from '@/common/components/Display/Group';
import { Stack } from '@/common/components/Display/Stack';
import { SiteProperties } from '@/common/models/site/SiteProperties';

import { backgroundStyle } from '../StyledBackground';

interface Props {
  siteProperties: SiteProperties;
  onSubmit?: () => void;
  children: ReactNode;
}
export const EntryConditionScreen: FC<Props> = ({
  siteProperties,
  onSubmit,
  children
}) => {
  const backgroundStyles = backgroundStyle({
    filterRgb:
      siteProperties.EntryConditionsFilter?.toString() ||
      siteProperties.BackgroundFilter?.toString(),
    color:
      siteProperties.EntryConditionsBackgroundColor ||
      siteProperties.BackgroundColor,
    image:
      siteProperties.EntryConditionsBackgroundImageModel ||
      siteProperties.BackgroundImageModel
  });

  return (
    <Group
      mih={'100%'}
      gap={0}
      justify="center"
      align="center"
      w={'100%'}
      style={backgroundStyles}
    >
      <Box
        c={siteProperties.EntryConditionsTextColor}
        w={'100%'}
        p={'2rem'}
        maw={500}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit?.();
          }}
        >
          <Stack gap={0} align={'center'}>
            {children}
            <input type="submit" style={{ display: 'none' }} />
          </Stack>
        </form>
      </Box>
    </Group>
  );
};
