import { FC } from 'react';

import { Center } from '@/common/components/Display/Center';
import { useModalsShown } from '@/common/components/Portal/DomPortalProvider';
import { useNewContentNotifier } from '@/front/components/site/SiteHost/useNewContentNotifier';

import { CardList } from '../CardList';
import { FrontEntryConditionScreen } from '../FrontEntryConditionScreen';
import { useUserContextSelector } from '../UserContext';
import { SiteCover } from './_Cover';
import { SiteBackground } from './SiteBackground/SiteBackground';
import { SiteFooter } from './SiteFooter';
import { SiteHeader } from './SiteHeader';
import { SiteDraftBanner } from './SiteHeader/DraftBanner/SiteDraftBanner';
import { useFrontPage, useFrontSite } from './Store';
import { useFrontPageCardsUpdatedSync } from './useFrontPageCardsUpdatedSync';

export const SiteHostContent: FC = () => {
  const currentUser = useUserContextSelector((s) => s.user);
  const siteId = useFrontSite((x) => x.site.id);
  const isGated = useFrontSite((x) => x.site.properties.EntryConditionsEnabled);
  const isHeaderEnabled = useFrontPage((x) => x.page.properties.HeaderEnabled);

  const modalShownCount = useModalsShown();
  const newCardNotifier = useNewContentNotifier({
    silenced: modalShownCount > 0,
    siteId
  });

  useFrontPageCardsUpdatedSync({
    onUpdate: newCardNotifier.update
  });

  if (isGated && !currentUser.hasPassedSiteEntryCondition) {
    return <FrontEntryConditionScreen />;
  }

  return (
    <SiteBackground>
      <SiteDraftBanner />
      {isHeaderEnabled && <SiteHeader />}
      <Center>
        <SiteCover />
        <CardList />
      </Center>
      <SiteFooter />
    </SiteBackground>
  );
};
